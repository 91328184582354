import api from 'api';
import { TokenCurrency } from 'types/currency';
import { NetworkName } from 'types/network';
import ResponseResult from 'types/response';

type CancelOrderParams = {
  orderId: string;
  address: string;
  network: NetworkName;
};

type CurrencyListParams = {
  currencyType: string;
};

type GetFeeValueParams = {
  network: string;
  contractAddress: string;
};

export const cancelOrder = ({ orderId, address, network }: CancelOrderParams) =>
  api.patch(`/order/cancel?network=${network}`, { orderId, address });

export const getCurrencyList = ({ currencyType }: CurrencyListParams) =>
  api.get(`/order/currency/${currencyType}`);

export const getFeeValue = ({ network, contractAddress }: GetFeeValueParams) =>
  api.get(`/${network}/api/v1/asset/${contractAddress}`);

export const getOrderCurrency = (
  currencyType: 'erc20' | 'erc721',
): Promise<ResponseResult<TokenCurrency[]>> =>
  api.get(`/order/currency/${currencyType}`);

import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useRouter } from 'next/router';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  breakpoints,
  fontSize,
  fontStyle,
  palette,
  spacing,
} from '@playdapp/ui';
import { Skeleton, SkeletonCircle } from '@chakra-ui/react';
import { useMedia } from 'react-use';
import axios from 'axios';

import type { Collection } from 'types/collections';
import type { NetworkName } from 'types/network';
import type { Order } from 'types/order';

import { getBreakpointQuery } from 'lib/util';
import { renderFungibleImage } from 'lib/render-placeholder';
import useConvertTokenName, { Counts } from 'hooks/useConvertTokenName';

import AuctionLabel from './AuctionLabel';
import Icon from './Icon';
import Image from './Image';

type Props = {
  /** token의 컬렉션 정보 */
  collectionInfo?: Collection;
  /** token의 이미지 정보 */
  imageUri?: string[] | string | null;
  /** video url */
  animationUri?: string | null;
  /** token id */
  tokenId?: string;
  /** token name */
  name?: string | null;
  /** token의 order 정보 */
  order?: Order;
  /** token 가격을 usd로 환산한 값 */
  convertedPrice?: string | null;
  /** token의 네트워크 정보 */
  network?: NetworkName;
  /** token에 대한 디테일 페이지 링크 */
  href?: string;
  /** token에 대한 like 개수 */
  like?: number | string | null;
  /** token이 속한 contract의 address */
  assetAddress?: string | null;
  /** token 메타데이터 속성(bundle 데이터 확인용) */
  attributes?: { [key: string]: number | string | null }[];
  /** 카드 컴포넌트 최대 크기(default: auto) */
  maxWidth?: number | string;
  /** token 소유자 여부 */
  isOwner?: boolean;
  /** 선택 가능한 상태 여부(my items에서 사용) */
  isSelectable?: boolean;
  /** 선택된 상태 여부(my items에서 사용) */
  isActive?: boolean;
  /** token 데이터 로딩 여부 */
  isLoading?: boolean;
  /** 선택 가능한 상태일 때 선택 비활성화 여부 */
  disabled?: boolean;
  /** 번들 여부 */
  isBundle?: boolean;
  /** 선택 가능한 상태에서 클릭했을 때 이벤트 */
  onClick?: (
    tokenId?: string,
    collectionInfo?: Collection,
    image?: string | null,
    title?: string | null,
    attributes?: Props['attributes'],
    assetAddress?: string | null,
    isDisbaled?: boolean,
  ) => () => void;
};

type StyledCardBlockProps = {
  isSelectable?: boolean;
  isActive?: boolean;
  disabled?: boolean;
  maxWidth?: number | string;
  hasImage: boolean;
};

type ImageInfo = {
  isGifImage: boolean;
  showImageType: 'error' | 'image' | 'video';
};

const containerStyle = css`
  display: flex;
  flex-direction: column;
`;

const CardLinkBlock = styled.a`
  ${containerStyle};
  cursor: pointer;
  z-index: 0;
`;

const CardButtonBlock = styled.div`
  ${containerStyle};
`;

const ItemImageBox = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 16px;
  border: 1px solid ${palette.gray300};
  transition: transform 0.2s ease-in-out;
  backface-visibility: hidden;

  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  ${breakpoints.down('sm')} {
    border-radius: 8px;
  }

  & > span {
    border-radius: 8px;
  }

  & > span > img,
  & > img,
  & > video {
    transition: transform 0.2s ease-in-out;
    transform: scale(1);
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 1;
  }
`;

const PlayIconBox = styled.div`
  position: absolute;
  bottom: 0px;
  right: 8px;
  z-index: 2;
`;

const SkeletonImageBox = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 16px;

  .chakra-skeleton::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  ${breakpoints.down('sm')} {
    border-radius: 8px;
  }
`;

const CardBlock = styled.div<StyledCardBlockProps>`
  position: relative;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(30, 31, 36, 0.15);
  border-radius: 16px;
  padding: ${spacing.s};
  transition: all 0.2s ease-in-out;
  z-index: 0;

  ${({ maxWidth }) => css`
    max-width: ${maxWidth};
  `}

  ${({ isSelectable, isActive }) =>
    isSelectable &&
    css`
      border: 4px solid ${isActive ? palette.marketplace : palette.gray600};
    `}

  &:hover {
    box-shadow: 0px 4px 8px rgba(30, 31, 36, 0.25);

    ${ItemImageBox} > img,
    ${ItemImageBox} > span > img,
    ${ItemImageBox} > video {
      transform: scale(
        ${({ hasImage, disabled }) => (hasImage && !disabled ? 1.15 : 1)}
      );
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

  ${breakpoints.down('md')} {
    padding: ${spacing.xs};
    border-radius: 12px;
  }
`;

const TopStatusBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing.xs};

  ${breakpoints.down('md')} {
    margin-bottom: ${({ theme }) => theme.rem(6)};
  }

  & > span {
    flex: none;
    border-radius: 50%;
  }
`;

const CollectionTag = styled.div`
  display: flex;
  align-items: center;
  max-width: 80%;
  padding: ${spacing.xxs} ${spacing.xs} ${spacing.xxs} ${spacing.xxs};
  border: 1px solid ${palette.gray300};
  border-radius: 11px;

  ${breakpoints.down('md')} {
    max-width: 60%;
  }

  & > span {
    flex: none;
    border-radius: 50%;
  }
`;

const CollectionTagText = styled.p`
  overflow: hidden;
  margin-left: ${spacing.xxs};
  font-size: ${fontSize.p6};
  color: ${palette.dgray300};
  line-height: 1.4;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const EmptyStatusBlock = styled.div`
  width: 100%;
  height: 1.5rem;

  ${breakpoints.down('md')} {
    max-width: 60%;
  }
`;

const BundleItemImageBlock = styled.div<{ imageCount: number }>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  flex: none;
  grid-gap: ${spacing.xxs};
  align-items: center;

  ${({ imageCount }) =>
    imageCount === 2 &&
    css`
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, 1fr);

      ${ItemImageBox}:first-of-type {
        grid-row-start: 2;
        grid-row-end: 4;
        grid-column-start: 1;
        grid-column-end: 3;
      }

      ${ItemImageBox}:last-of-type {
        grid-row-start: 2;
        grid-row-end: 4;
        grid-column-start: 3;
        grid-column-end: 5;
      }
    `}
`;

const BundleMoreImageBadge = styled.div`
  position: absolute;
  bottom: ${spacing.xs};
  right: ${spacing.xs};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  font-size: ${fontSize.p6};
  line-height: 1.5;
  color: ${palette.black};
  filter: drop-shadow(0px 4px 8px rgba(30, 31, 36, 0.15));
  z-index: 2;
`;

const EmptyImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% + 0.125rem);
  height: calc(100% + 0.125rem);
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  backdrop-filter: blur(24px);
  z-index: 2;
  backface-visibility: hidden;
`;

const RefreshIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 36px;
  height: 36px;
  border: 1px solid ${palette.white};
  border-radius: 50%;

  ${breakpoints.down('md')} {
    width: 32px;
    height: 32px;

    & > span,
    & > span > img {
      width: 16px !important;
      height: 16px !important;
    }
  }
`;

const EmptyImageOverlayText = styled.span`
  margin: ${spacing.xs} ${spacing.xl} 0 ${spacing.xl};
  font-size: ${fontSize.p4};
  font-weight: 400;
  line-height: 1.5;
  color: ${palette.white};
  text-align: center;

  ${breakpoints.down('lg')} {
    font-size: ${fontSize.p5};
  }
`;

const TokenNameBlock = styled.div`
  margin-top: ${spacing.xs};

  .chakra-skeleton {
    border-radius: 8px;
  }

  ${breakpoints.down('md')} {
    margin-top: ${({ theme }) => theme.rem(6)};
  }
`;

const TokenNameText = styled.p`
  overflow: hidden;
  font-size: ${fontSize.b3};
  font-weight: 600;
  line-height: 1.5;
  color: ${palette.black};
  text-overflow: ellipsis;
  white-space: nowrap;

  ${breakpoints.down('lg')} {
    font-size: ${fontSize.b4};
  }

  ${breakpoints.down('md')} {
    font-size: ${fontSize.p5};
  }
`;

const TokenPriceBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: ${spacing.xxs};

  .chakra-skeleton {
    border-radius: 8px;
  }
`;

const TokenPriceTextBlock = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TokenPriceText = styled.span`
  color: ${palette.black};
  font-size: ${fontSize.b4};
  font-weight: 600;

  ${breakpoints.down('lg')} {
    font-size: ${fontSize.b5};
  }

  ${breakpoints.down('md')} {
    font-size: ${fontSize.b6};
  }
`;

const TokenLikeBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: ${spacing.xxs};
`;

const TokenLikeText = styled.span`
  margin-left: ${spacing.xxs};
  color: ${palette.dgray100};
  ${fontStyle('p4')};

  ${breakpoints.down('lg')} {
    ${fontStyle('p5')};
  }

  ${breakpoints.down('md')} {
    ${fontStyle('p6')};
  }
`;

const TokenNotSaleText = styled.span`
  color: ${palette.gray900};
  font-size: ${fontSize.p4};
  font-weight: 400;

  ${breakpoints.down('lg')} {
    font-size: ${fontSize.p5};
  }

  ${breakpoints.down('md')} {
    font-size: ${fontSize.p6};
  }
`;

const ConvertPriceText = styled.span`
  margin-left: ${({ theme }) => theme.rem(6)};
  color: ${palette.dgray100};
  font-size: ${fontSize.p4};
  font-weight: 400;

  ${breakpoints.down('lg')} {
    width: auto;
    font-size: ${fontSize.p5};
  }

  ${breakpoints.down('md')} {
    font-size: ${fontSize.p6};
  }
`;

const ButtonStatusBlock = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-top: ${spacing.s};

  ${breakpoints.down('lg')} {
    margin-top: ${spacing.xs};
  }

  ${breakpoints.down('md')} {
    margin-top: ${({ theme }) => theme.rem(6)};
  }
`;

const SaleStatusText = styled.p<{ color?: string }>`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  order: 2;
  font-size: ${fontSize.p6};
  color: ${({ color }) => color || palette.dgray100};
`;

const SaleStatusPriceText = styled.span`
  font-weight: 600;
`;

const NetworkStatusBlock = styled.div`
  width: 18px;
  height: 18px;
  margin-left: ${spacing.xs};
`;

const SelectCheckBox = styled.div<{ isActive?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: ${({ isActive }) =>
    isActive ? palette.primary500 : palette.gray900};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  z-index: 2;

  ${breakpoints.down('md')} {
    width: 48px;
    height: 48px;

    & > span,
    & > span > img {
      width: 24px !important;
      height: 24px !important;
    }
  }
`;

let isVideoPlayClicked = false;

const convertCounts: Counts = {
  default: {
    start: 6,
    end: 12,
  },
  xxlDown: {
    start: 5,
    end: 12,
  },
  xlDown: {
    start: 4,
    end: 12,
  },
  smDown: {
    start: 8,
    end: 12,
  },
  xsDown: {
    start: 4,
    end: 13,
  },
};

const CardElementWrapper: React.FC<
  Pick<
    Props,
    | 'assetAddress'
    | 'attributes'
    | 'collectionInfo'
    | 'disabled'
    | 'href'
    | 'imageUri'
    | 'isLoading'
    | 'isSelectable'
    | 'like'
    | 'name'
    | 'onClick'
    | 'tokenId'
  >
> = ({
  children,
  href,
  isSelectable,
  isLoading,
  tokenId,
  collectionInfo,
  imageUri,
  name,
  attributes,
  assetAddress,
  disabled,
  onClick,
}) => {
  const router = useRouter();

  const handleClickCheck = (href?: string) => {
    if (isVideoPlayClicked) {
      isVideoPlayClicked = false;
      return;
    }

    router.push(href || '/');
  };

  if (isLoading) {
    return <div>{children}</div>;
  }

  if (isSelectable)
    return (
      <CardButtonBlock
        role="button"
        tabIndex={0}
        onClick={() => {
          if (isVideoPlayClicked) {
            isVideoPlayClicked = false;
            return;
          }

          if (onClick) {
            onClick(
              tokenId,
              collectionInfo,
              imageUri as string,
              name,
              attributes,
              assetAddress,
              disabled,
            )();
          }
        }}
      >
        {children}
      </CardButtonBlock>
    );

  return (
    <CardLinkBlock onClick={() => handleClickCheck(href)}>
      {children}
    </CardLinkBlock>
  );
};

/**
 * NFT 정보에 대한 카드 컴포넌트
 */
const Card = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      collectionInfo,
      imageUri,
      animationUri,
      tokenId,
      name,
      order,
      convertedPrice,
      network,
      href,
      assetAddress,
      attributes,
      maxWidth,
      like,
      isOwner,
      isSelectable,
      isActive,
      isLoading,
      disabled,
      isBundle,
      onClick,
    },
    ref,
  ) => {
    const playIconBoxElement = useRef<HTMLDivElement>(null);
    const isMobile = useMedia(getBreakpointQuery(breakpoints.down('sm')));

    const [isVideoPlay, setIsVideoPlay] = useState(false);
    const [isVideoPlayHover, setIsVideoPlayHover] = useState(false);
    const [isVideoError, setIsVideoError] = useState(false);
    const [isImageError, setIsImageError] = useState(false);
    const [isBundleVideoError, setIsBundleVideoError] = useState<boolean[]>([
      false,
      false,
      false,
      false,
    ]);
    const [isBundleImageError, setIsBundleImageError] = useState<boolean[]>([
      false,
      false,
      false,
      false,
    ]);

    const convertName = useConvertTokenName(
      convertCounts,
      name,
      collectionInfo?.categoryId,
    );

    const imageInfo: ImageInfo = useMemo(() => {
      if (!imageUri && !animationUri) {
        return {
          isGifImage: false,
          showImageType: 'error',
        };
      }

      const isGifImage =
        typeof imageUri === 'string' && /\.(gif)$/i.test(imageUri);

      if (!isGifImage && animationUri && isVideoPlay) {
        return {
          isGifImage,
          showImageType: 'video',
        };
      }

      return {
        isGifImage,
        showImageType: 'image',
      };
    }, [imageUri, animationUri, isVideoPlay]);

    const handleVideoPlayClick = () => {
      isVideoPlayClicked = true;
      setIsVideoPlay(!isVideoPlay);
    };

    // image 검사
    useEffect(() => {
      if (typeof imageUri === 'string') {
        if (!/\.(gif|jpe?g|tiff?|png|webp|bmp|mp4|mov)$/i.test(imageUri)) {
          (async () => {
            const res = await axios.get(imageUri);

            if (!/image/i.test(res.headers['content-type'])) {
              setIsImageError(true);
            }
          })();
        }
      } else if (imageUri && typeof imageUri === 'object') {
        const requestList = imageUri.map((image, index) => {
          if (!/\.(gif|jpe?g|tiff?|png|webp|bmp|mp4|mov)$/i.test(image)) {
            return (async () => {
              const res = await axios.get(image);

              if (!/image/i.test(res.headers['content-type'])) {
                setIsBundleImageError((prevState) => [
                  ...prevState.slice(0, index),
                  true,
                  ...prevState.slice(index + 1),
                ]);
              }
            })();
          }
          return new Promise((resolve) => resolve);
        });

        Promise.all(requestList);
      }
    }, [imageUri]);

    useEffect(() => {
      if (!playIconBoxElement.current) return;

      const playIconBox = playIconBoxElement.current;

      playIconBox.addEventListener('mouseenter', () => {
        setIsVideoPlayHover(true);
      });
      playIconBox.addEventListener('mouseleave', () => {
        setIsVideoPlayHover(false);
      });
    }, []);

    return (
      <CardElementWrapper
        isSelectable={isSelectable}
        isLoading={isLoading}
        href={href}
        tokenId={tokenId}
        collectionInfo={collectionInfo}
        imageUri={imageUri}
        name={name}
        like={like}
        assetAddress={assetAddress}
        disabled={disabled}
        attributes={attributes}
        onClick={onClick}
      >
        <CardBlock
          isSelectable={isSelectable}
          isActive={isActive}
          disabled={disabled}
          maxWidth={maxWidth}
          hasImage={!!imageUri}
          ref={ref}
        >
          <TopStatusBlock>
            {isLoading && (
              <>
                <Skeleton width="50%" height="24px" borderRadius={24} />
                <SkeletonCircle size="24px" />
              </>
            )}
            {!isLoading && collectionInfo && (
              <CollectionTag>
                <Image
                  src={collectionInfo.thumbnailUrl}
                  alt="card-collection-thumb"
                  width={14}
                  height={14}
                />
                <CollectionTagText>{collectionInfo.title}</CollectionTagText>
              </CollectionTag>
            )}
            {!isLoading && collectionInfo && isOwner && (
              <Icon name="my" size={20} />
            )}
            {!isLoading && !collectionInfo && !isOwner && <EmptyStatusBlock />}
            {!isLoading && !collectionInfo && isOwner && (
              <>
                <EmptyStatusBlock />
                <Icon name="my" size={20} />
              </>
            )}
          </TopStatusBlock>

          {isLoading && (
            <SkeletonImageBox>
              <Skeleton width="100%" height="100%" />
            </SkeletonImageBox>
          )}

          {!isLoading &&
            (imageInfo.showImageType === 'error' ||
              isImageError ||
              isVideoError) && (
              <ItemImageBox>
                <Image
                  src={
                    collectionInfo?.thumbnailUrl ||
                    'https://images.playdapp.com/marketplace-dev/collections/placeholder2v.png'
                  }
                  alt="card-thumb"
                  layout="fill"
                />
                <EmptyImageOverlay>
                  <RefreshIcon>
                    <Icon name="refresh-white" size={20} />
                  </RefreshIcon>
                  <EmptyImageOverlayText>
                    Content not available yet
                  </EmptyImageOverlayText>
                </EmptyImageOverlay>
              </ItemImageBox>
            )}

          {!isLoading &&
            !isImageError &&
            !isVideoError &&
            imageInfo.showImageType !== 'error' &&
            typeof imageUri !== 'object' && (
              <ItemImageBox>
                {imageInfo.showImageType === 'image' && (
                  <Image
                    src={imageUri as string}
                    alt="token"
                    layout="fill"
                    onError={renderFungibleImage(
                      collectionInfo?.thumbnailUrl ||
                        'https://images.playdapp.com/marketplace-dev/collections/placeholder2v.png',
                    )}
                    unoptimized
                  />
                )}

                {imageInfo.showImageType === 'video' && isVideoPlay && (
                  <video
                    src={animationUri as string}
                    poster={typeof imageUri === 'string' ? imageUri : undefined}
                    muted
                    loop
                    playsInline
                    autoPlay
                    onError={() => {
                      setIsVideoError(true);
                    }}
                  />
                )}

                {typeof animationUri === 'string' && !imageInfo.isGifImage && (
                  <PlayIconBox ref={playIconBoxElement}>
                    <Icon
                      name={`video-${isVideoPlay ? 'stop' : 'play'}${
                        isVideoPlayHover ? '-hover' : ''
                      }`}
                      size={isMobile ? 24 : 40}
                      onClick={handleVideoPlayClick}
                    />
                  </PlayIconBox>
                )}

                {order?.auction && (
                  <AuctionLabel auctionExpireAt={order.auction.expireAt} />
                )}
              </ItemImageBox>
            )}

          {!isLoading && imageUri && typeof imageUri === 'object' && (
            <BundleItemImageBlock imageCount={imageUri.length}>
              {imageUri.slice(0, 4).map((image, index) => (
                <ItemImageBox key={`${image}-${index}`}>
                  {image &&
                    !isBundleVideoError[index] &&
                    (/.(mp4|mov)$/.test(image) ? (
                      <video
                        src={image}
                        muted
                        autoPlay
                        loop
                        playsInline
                        onError={() => {
                          setIsBundleVideoError((prevState) => [
                            ...prevState.slice(0, index),
                            true,
                            ...prevState.slice(index + 1),
                          ]);
                        }}
                      />
                    ) : (
                      <Image
                        src={image}
                        alt=""
                        layout="fill"
                        onError={renderFungibleImage(
                          collectionInfo?.thumbnailUrl ||
                            'https://images.playdapp.com/marketplace-dev/collections/placeholder2v.png',
                        )}
                        unoptimized
                      />
                    ))}
                  {(!image ||
                    isBundleVideoError[index] ||
                    isBundleImageError[index]) && (
                    <>
                      <Image
                        prefix="s3"
                        src="/collections/placeholder2v.png"
                        alt="card-thumb"
                        layout="fill"
                      />
                      <EmptyImageOverlay>
                        <RefreshIcon>
                          <Icon name="refresh-white" size={20} />
                        </RefreshIcon>
                        <EmptyImageOverlayText>
                          Content not available yet
                        </EmptyImageOverlayText>
                      </EmptyImageOverlay>
                    </>
                  )}
                </ItemImageBox>
              ))}
              {imageUri.length > 4 && (
                <BundleMoreImageBadge>
                  +{imageUri.length - 4}
                </BundleMoreImageBadge>
              )}
            </BundleItemImageBlock>
          )}

          <TokenNameBlock>
            {isLoading && <Skeleton width="70%" height="24px" />}
            {!isLoading && (
              <TokenNameText>
                {convertName && convertName}
                {!convertName && tokenId && tokenId}
                {!convertName && !tokenId && '-'}
              </TokenNameText>
            )}
          </TokenNameBlock>

          <TokenPriceBlock>
            {isLoading && <Skeleton width="80%" height="20px" />}
            {!isLoading && order && (
              <TokenPriceTextBlock>
                {order.sale ? (
                  <TokenPriceText>
                    {order.sale.price?.toLocaleString(undefined, {
                      maximumFractionDigits: 4,
                    })}{' '}
                    {order.sale.currency}
                    {convertedPrice && (
                      <ConvertPriceText>(≈ ${convertedPrice})</ConvertPriceText>
                    )}
                  </TokenPriceText>
                ) : (
                  <TokenNotSaleText>Not for sale</TokenNotSaleText>
                )}
              </TokenPriceTextBlock>
            )}
          </TokenPriceBlock>

          <TokenLikeBlock>
            {isLoading && <Skeleton width="80%" height="20px" />}
            {!isLoading && (
              <>
                <Icon
                  name={isBundle ? 'heart-bundle-gray' : 'heart-single-gray'}
                  size={isBundle ? 24 : 14.96}
                />
                <TokenLikeText>{like || '0'}</TokenLikeText>
              </>
            )}
          </TokenLikeBlock>

          <ButtonStatusBlock>
            {isLoading && (
              <>
                <SkeletonCircle size="20px" />
                <Skeleton width="50%" height="20px" borderRadius={24} />
              </>
            )}
            {!isLoading && order && order?.lastSoldInfo && (
              <SaleStatusText color="#9D740C">
                Last sold {!isMobile && 'for'}&nbsp;
                <SaleStatusPriceText>
                  {order?.lastSoldInfo.price} {order?.lastSoldInfo.currency}
                </SaleStatusPriceText>
              </SaleStatusText>
            )}
            {!isLoading && order && !order?.lastSoldInfo && order.offer && (
              <SaleStatusText color="#1766F9">
                Offer {!isMobile && 'for'}&nbsp;
                <SaleStatusPriceText>
                  {order.offer.highestOfferPrice}{' '}
                  {order.offer.highestOfferCurrency}
                </SaleStatusPriceText>
              </SaleStatusText>
            )}
            {!isLoading && network && (
              <NetworkStatusBlock>
                <Icon name={`brand-${network}`} width={16} height={14} />
              </NetworkStatusBlock>
            )}
          </ButtonStatusBlock>

          {!isLoading && isSelectable && !disabled && (
            <SelectCheckBox isActive={isActive}>
              <Icon
                name={`selected${isActive ? '-primary100' : ''}`}
                size={32}
                alt="selected-large"
              />
            </SelectCheckBox>
          )}
        </CardBlock>
      </CardElementWrapper>
    );
  },
);

const areEqual = (prevProps: Props, nextProps: Props) => {
  if (!!prevProps.isSelectable || !!nextProps.isSelectable) return false;
  return prevProps.tokenId === nextProps.tokenId;
};

export default React.memo(Card, areEqual);

import { useMemo } from 'react';
import { useMedia } from 'react-use';
import { breakpoints } from '@playdapp/ui';

import { getBreakpointQuery, convertAddress } from 'lib/util';

type Count = {
  start: number;
  end: number;
};

export type Counts = {
  [key: string]: Count;
};

const checkCategoryId =
  process.env.NEXT_PUBLIC_ENV === 'production' ? [1, 14] : [1, 28];

const useConvertTokenName = (
  counts: Counts,
  name?: string | null,
  collectionsId?: number,
) => {
  const isXxlDownScreen = useMedia(getBreakpointQuery(breakpoints.down('xxl')));
  const isXlDownScreen = useMedia(getBreakpointQuery(breakpoints.down('xl')));
  const isLgDownScreen = useMedia(getBreakpointQuery(breakpoints.down('lg')));
  const isSmDownScreen = useMedia(getBreakpointQuery(breakpoints.down('sm')));
  const isXsDownScreen = useMedia(
    getBreakpointQuery('@media (max-width: 400px)'),
  );
  const isXxsDownScreen = useMedia(
    getBreakpointQuery('@media (max-width: 359px)'),
  );

  const convertName = useMemo(() => {
    const checkMaxLength =
      isXsDownScreen || (!isLgDownScreen && isXlDownScreen) ? 18 : 20;
    let countsIndex = 'default';

    if (!name) return '';
    if (
      name.length < checkMaxLength ||
      !collectionsId ||
      !checkCategoryId.find((item) => item === collectionsId) ||
      isXxsDownScreen ||
      (isLgDownScreen && !isXsDownScreen && !isSmDownScreen)
    ) {
      return name;
    }

    if (isXsDownScreen) countsIndex = 'xsDown';
    else if (isSmDownScreen) countsIndex = 'smDown';
    else if (isXlDownScreen) countsIndex = 'xlDown';
    else if (isXxlDownScreen) countsIndex = 'xxlDown';

    return convertAddress(
      name,
      counts[countsIndex].start,
      counts[countsIndex].end,
    );
  }, [
    name,
    isXxlDownScreen,
    isXlDownScreen,
    isLgDownScreen,
    isSmDownScreen,
    isXsDownScreen,
    isXxsDownScreen,
  ]);

  return convertName;
};

export default useConvertTokenName;

import styled from '@emotion/styled';
import useTimeTracker from 'hooks/useTimeTracker';
import { fontSize, spacing, Typography } from '@playdapp/ui';
import Icon from './Icon';

const Label = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
  background: #fbdaed;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  span {
    font-size: ${fontSize.b4};
    color: #da46a6;
  }

  span + span {
    margin-left: ${spacing.xxs};
  }
`;

type Props = {
  auctionExpireAt?: number;
};

const AuctionLabel = ({ auctionExpireAt = 0 }: Props) => {
  const { time, isEnded } = useTimeTracker(auctionExpireAt);

  if (isEnded) return null;

  return (
    <Label>
      <Icon name="alarm" size={18} alt="auction-clock" />
      <Typography type="b3">
        {time.days}d&nbsp;{time.hours}h&nbsp;
        {time.minutes}m&nbsp;{time.seconds}s&nbsp;
      </Typography>
      <span>🔥</span>
    </Label>
  );
};

export default AuctionLabel;

import { useState, useEffect } from 'react';

import TimeRemain from 'types/TimeRemain';
import { getTimeRemain } from 'lib/util';

const useTimeTracker = (endTime = 0) => {
  const [time, setTime] = useState<TimeRemain>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [timestamp, setTimeStamp] = useState(endTime);
  const [isEnded, setIsEnded] = useState(true);

  const updateTimeStamp = (time: number) => {
    setTimeStamp(time);
  };

  useEffect(() => {
    if (timestamp) {
      setIsEnded(false);
      const id = setInterval(() => {
        const timeRemain = getTimeRemain(timestamp);

        if (timeRemain) {
          const { days, hours, minutes, seconds } = timeRemain;

          if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0)
            setIsEnded(true);

          setTime({
            days,
            hours: hours.toString().padStart(2, '0'),
            minutes: minutes.toString().padStart(2, '0'),
            seconds: seconds.toString().padStart(2, '0'),
          });
        }
      }, 1000);
      return () => {
        clearInterval(id);
      };
    }
  }, [timestamp]);

  return {
    time,
    isEnded,
    updateTimeStamp,
  };
};

export default useTimeTracker;
